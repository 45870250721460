// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_nYAYbkn6kimpke88pNeIzFxGLbMas_w",
  authDomain: "my-portfolio-56b50.firebaseapp.com",
  projectId: "my-portfolio-56b50",
  storageBucket: "my-portfolio-56b50.firebasestorage.app",
  messagingSenderId: "774792172373",
  appId: "1:774792172373:web:30df83616c168d9e4695dc",
  measurementId: "G-5C9409RKM7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
